$.extend(true, window.locales.es, {
	digitalDownloads: {
		accessCode: 'Código de acceso',
		accessCodeGo: '¡Vamos!',
		accessCodeMissing: 'Ese código de acceso no existe',
		accessCodeRequired: 'Por favor ingrese un código de acceso',
		accessCodeShort: 'El código de acceso es demasiado corto',
		accessCodePrompt: 'Por favor ingrese el código que le dieron para acceder a tus fotos',
		broughtToYouBy: 'Traído a usted por',
		copyrightRelease: 'Liberación de derechos de autor',
		downloadAll: 'Descargar todo',
		downloadFor: 'Descargas digitales para %{name}',
		loadError: 'Error al cargar la entrega',
		shareEmail: 'Compartir por correo electrónico',
		shareFacebook: 'Compartir en Facebook',
		shareTwitter: 'Compartir en Twitter',
		title: 'Descargas Digitales'
	}
});