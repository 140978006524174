$.extend(true, window.locales.en, {
	management: {
		goToProjectButton: 'false'
	},
	projects: {
		
	},
	digitalDownloads: {
		accessCode: 'Access Code',
		accessCodeGo: 'Go!',
		accessCodeMissing: 'That access code does not exist',
		accessCodeRequired: 'Please enter an Access Code',
		accessCodeShort: 'Access Code is too short',
		accessCodePrompt: 'Please enter the access code that you were given to access your photos',
		broughtToYouBy: 'Brought to you by',
		copyrightRelease: 'Copyright Release',
		download: 'Download',
		downloadAll: 'Download all',
		downloadFor: 'Digital downloads for %{name}',
		loadError: 'Failed to load delivery',
		shareEmail: 'Share to Email',
		shareFacebook: 'Share to Facebook',
		shareTwitter: 'Share to Twitter',
		title: 'Digital Downloads'
	},
	organizations: {
		name: 'Customer Name',
		newOrganization: 'Create new customer',
		organization: 'Customer',
		organizationChoice: 'Choose your customer'
	}
});